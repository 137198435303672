
import { Options, Prop } from "vue-property-decorator";
import TitleAndAdditionalText from "@/components/Pages/Parts/TitleAndAdditionalText.vue";
import MediaMultiChoice from "@/components/Pages/TheoryPreExams/Parts/MediaMultiChoice.vue";
import MultiChoice from "@/components/Pages/TheoryPreExams/Parts/MultiChoice.vue";
import FreeText from "@/components/Pages/TheoryPreExams/Parts/FreeText.vue";
import QuestionMixin from "@/mixins/TheoryQuestions/QuestionMixin";
import { mixins } from "vue-class-component";
import ModalMixin from "@/mixins/ModalMixin";

@Options({
  components: { TitleAndAdditionalText, FreeText, MultiChoice, MediaMultiChoice },
})
export default class BaseQuestion extends mixins(QuestionMixin, ModalMixin) {
  public name = "BaseQuestion";

  @Prop({ default: false })
  public disabled!: any;

  private currentImage = "";

  private showImage(value: any, e: PointerEvent) {
    this.currentImage = value;
    this.showModal(e, "imageModal");
  }

  private hideImage(e: PointerEvent) {
    this.hideModal(e, "imageModal");
  }
}
