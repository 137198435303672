import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "image-question-wrapper my-5" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex"
}
const _hoisted_3 = {
  key: 1,
  class: "d-flex"
}
const _hoisted_4 = {
  key: 2,
  class: "d-flex"
}
const _hoisted_5 = { class: "w-75 d-flex justify-content-center align-items-center" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleAndAdditionalText = _resolveComponent("TitleAndAdditionalText")!
  const _component_MediaMultiChoice = _resolveComponent("MediaMultiChoice")!
  const _component_MultiChoice = _resolveComponent("MultiChoice")!
  const _component_FreeText = _resolveComponent("FreeText")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_fsc_modal = _resolveComponent("fsc-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_b_col, { class: "d-flex justify-content-center flex-column" }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_TitleAndAdditionalText, { question: _ctx.question }, null, 8, ["question"]),
              (_ctx.question.questionType === 'MULTIPLE_CHOICE_WITH_MEDIA')
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_MediaMultiChoice, {
                      question: _ctx.question,
                      disabled: _ctx.disabled,
                      onShowImage: _ctx.showImage,
                      "show-correct-answers": _ctx.showCorrectAnswers,
                      onOnAnswerGiven: _cache[0] || (_cache[0] = ($event: any) => (_ctx.answerGiven($event)))
                    }, null, 8, ["question", "disabled", "onShowImage", "show-correct-answers"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.question.questionType === 'MULTIPLE_CHOICE')
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_MultiChoice, {
                      question: _ctx.question,
                      disabled: _ctx.disabled,
                      "show-correct-answers": _ctx.showCorrectAnswers,
                      onOnAnswerGiven: _cache[1] || (_cache[1] = ($event: any) => (_ctx.answerGiven($event)))
                    }, null, 8, ["question", "disabled", "show-correct-answers"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.question.questionType === 'FREE_TEXT')
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_FreeText, {
                      question: _ctx.question,
                      disabled: _ctx.disabled,
                      "show-correct-answers": _ctx.showCorrectAnswers,
                      onOnAnswerGiven: _cache[2] || (_cache[2] = ($event: any) => (_ctx.answerGiven($event)))
                    }, null, 8, ["question", "disabled", "show-correct-answers"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_fsc_modal, {
      ref: "imageModal",
      centered: "",
      size: "xl",
      "custom-class": "custom-modal-wrapper",
      id: "imageModal"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.hideImage && _ctx.hideImage(...args))),
          class: "d-flex justify-content-center"
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              alt: "",
              style: {"height":"100%"},
              src: _ctx.currentImage
            }, null, 8, _hoisted_6)
          ])
        ])
      ]),
      _: 1
    }, 512)
  ]))
}