
import { defineComponent, ref, watch, computed } from "vue";

export default defineComponent({
  name: "MediaMultiChoice",
  emits: ["onAnswerGiven", "showImage", "onAnswer"],

  components: {},
  props: {
    question: {
      type: Object,
      required: true,
    },
    showCorrectAnswers: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const answers = ref<Array<any>>([]);
    const options = ref<Array<any>>([]);
    const answered = ref(false);

    const getOptionPosition = (option: any, index: number) => {
      return option.position ?? index + 1;
    };

    const getMediaUrl = (optionPosition: number, fullSize: boolean): string => {
      const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
      const option = fullSize ? "reducedSize=false" : "reducedSize=true&thumbnail=START";
      return `${baseUrl}/theory-questions/${props.question.id}/${optionPosition}/media/option?${option}`;
    };

    const onAnswerChange = (value: boolean, position: any): void => {
      const answer = answers.value.find((a) => a.position === position);
      if (answer) {
        answer.answer = value;
        answer.correct = answer.value === value;
        answered.value = answers.value.some((a) => a.answer);
        answerGiven();
      }
    };

    const isAnswerCorrect = computed(() => {
      return answers.value.every((a) => a.correct);
    });

    const answerGiven = (): void => {
      emit("onAnswerGiven", {
        given: answered.value,
        answer: answers.value,
        correct: isAnswerCorrect.value,
      });
    };

    const isAnswerIndexCorrectStyle = (answer: any) => {
      return answer ? "visibility: visible; margin-top: 0.6rem;" : "visibility: hidden; margin-top: 0.6rem;";
    };

    const onClick = (value: any) => {
      emit("showImage", value);
    };

    watch(
      () => props.question,
      (newQuestion) => {
        if (newQuestion) {
          options.value = [...newQuestion.options];
          if (newQuestion.answer) {
            answers.value = newQuestion.answer;
            options.value = options.value.map((option) => {
              const value = newQuestion.answer.some((answer: any) => answer.position === option.position && answer.answer);
              return { ...option, value };
            });
          } else {
            options.value = options.value.map((option) => ({ ...option, value: false }));
            answers.value = options.value.map((option) => {
              const answerPositions = newQuestion.correctAnswer.split(";");
              const value = answerPositions.includes(String(option.position));
              return {
                position: option.position,
                value,
                correct: !value,
                answer: false,
              };
            });
          }
        }
      },
      { immediate: true, deep: true }
    );

    return {
      options,
      answers,
      answered,
      getOptionPosition,
      getMediaUrl,
      onAnswerChange,
      isAnswerIndexCorrectStyle,
      onClick,
    };
  },
});
